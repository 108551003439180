@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";

.ikona_fb { background: url("/img/fb_icon.png") no-repeat;height:53px;width:54px;display:block;position:fixed;top:100px;right:0;z-index:999 }
.ikona_yt { background: url("/img/yt.png") no-repeat;height:53px;width:54px;display:block;position:fixed;top:150px;right:0;z-index:999 }

body {
  background:#fff;
}   

iframe { max-width:100% }

img { max-width:100%;display: block; }

.clear-row-col12 > div:nth-child(0n+1) {  clear:left;margin-bottom:20px }
.clear-row-col6 > div:nth-child(2n+1) {  clear:left;margin-bottom:20px } 
.clear-row-col4 > div:nth-child(3n+1) {  clear:left;margin-bottom:20px }
.clear-row-col3 > div:nth-child(4n+1) {  clear:left;margin-bottom:20px }
.clear-row-col2 > div:nth-child(6n+1) {  clear:left;margin-bottom:20px }  

.cookie_warning{ background:#336072;padding: 10px 0px;position: fixed;width: 100%;bottom: 0;left: 0;z-index:9999;color:#fff;
 }
.cookie_warning .zamknij { color:#fff;text-align:right;display:block;cursor:pointer; }

@media screen and (max-width: 992px) {
  .cookie_warning .zamknij b {  
    font-size: 20px;
  }
  .cookie_warning{
    font-size:10px
  }  
}

@media screen and (max-width: 768px) {
  .cookie_warning .container { margin:6px }
}

.btn-aemail {  
background: transparent url("/img/icon_email.png") no-repeat right center;
border-radius: 5px;
height: 30px;
display:block !important;
font-size: 0px;
width: 30px;margin: 0 auto;
}

.aemail { display: block !important;height: 30px;padding-top: 6px; }

.btn-tel {  
background: transparent url("/img/icon_phone.png") no-repeat right center;
border-radius: 5px;
height: 30px;
display:block !important;
font-size: 0px;
width: 30px;margin: 0 auto;
}

.contact_table tel[data-phone] { height: 46px;padding-top: 14px;display: block; }

.nawiguj_do_btn {
background: transparent url("/img/icon_nawiguj.png") no-repeat scroll right center;
border-radius: 5px;
height: 30px;
display: block !important;
font-size: 0px;
width: 30px;
margin: 0px auto;

}

.btn-skype {  
background: transparent url("/img/icon_skype.png") no-repeat right center;
border-radius: 5px;
height: 30px;
display:block !important;
font-size: 0px;
width: 30px;margin: 0 auto;
}

.dane_kontaktowe .aemail { display:inline !important }

.link-skype { display: block;height: 37px;padding-top: 8px; }

.nawiguj_do { display: block;height: 20px; }

.contact_table { border-spacing: 3px;border-collapse: inherit; }
.contact_table p { margin:0 }
.contact_table td { padding:3px;/*background: #F2F2F2;*/ }


.original_website { text-align: center;display: block;padding-bottom: 10px;color:#FF8E05; }
.original_website:hover { color:#FF8E05 }

/****************************** clear bootrap **************************************************/
#clear_bootstrap{
  .navbar{ min-height: 87px !important; }
  .navbar-inverse { border:0; }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover { background:none; }
  .navbar-inverse  .navbar-nav > li > a.active { }
  .navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a { text-shadow:none;text-transform:uppercase;color:#4b4b4b;font-size:16px }
  .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover { color:#4b4b4b }     
  .navbar-right { margin: 25px 0px 0px 0px; }
  .nav > li > a { padding:10px 27px } 
  .navbar-inverse .navbar-toggle { border:0; }
  .navbar-inverse .navbar-toggle .icon-bar { background-color:#21709D; }
  .navbar-toggle { background:none }
  .navbar-nav .open .dropdown-menu { background:#fff }
  .navbar-inverse { background-image: none }
  .navbar-inverse { background: #fff; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color:#fff}
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form { border-color: #101010; border: 0;}
  .navbar-toggle .icon-bar {display: block;width: 22px;height: 3px;border-radius: 1px; }
  .navbar-inverse .navbar-toggle { margin-top:25px }
  .dropdown-menu { border-radius:0 }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #000; }
  .navbar-brand > img { max-width: 160px;margin-top: 8px; }  
  #main-carousel .carousel-inner > .item { height: 500px; }
  #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 40%;right: 40%; }
  #main-carousel .carousel-caption { bottom: 40%; }
  #main-carousel .carousel-indicators {bottom: 70px;}
  .content_short_front { height: 123px;overflow: hidden; }
  #footer { background: rgb(33, 112, 157) none repeat scroll 0% 0%;color: rgb(255, 255, 255);margin-top: 50px; }
  #footer p { margin:10px 0 }
  
  .news_content_short { height: 143px;overflow: hidden; }
  .product_content_short { height: 143px;overflow: hidden; }
  
  @media screen and (max-width: 768px) {
    #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%; }
  }
  
  #bxslider1 .zobacz_wiecej { position: absolute; left:0; top:0; width:100%; height:100%; z-index:1000;background: rgb(51, 122, 183) none repeat scroll 0% 0%;
color:#fff;padding: 20px 30px;display: block;width: 170px; }

}



/****************************** layoutmobi2 **************************************************/
#layoutmobi2{

  @color_white: #fff;
  @color_rozowy: #F33B24;
  @color_czorny: #292929;   
  

  .navbar{ min-height: 87px !important; -webkit-box-shadow: 1px 1px 5px 5px #808080;box-shadow: 1px 1px 5px 5px #808080;
  background:#00C6D8 !important;border-radius: 0px; }
  .navbar-inverse { border:0; }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover { background:none; }
  .navbar-inverse  .navbar-nav > li > a.active { }
  .navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a { text-shadow:none;text-transform:uppercase;color:#fff;font-size:16px }
  .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover { color: @color_czorny; }     
  .navbar-right { margin: 25px 0px 0px 10px; }
  .nav > li { list-style-type: disc;display: list-item;margin-right: 30px;color: #fff; }
  .nav > li > a { padding-left:0px } 
  .navbar-inverse .navbar-toggle { border:0; }
  .navbar-inverse .navbar-toggle .icon-bar { background-color:@color_czorny; }
  .navbar-toggle { background:none }
  .navbar-nav .open .dropdown-menu { background:#fff }
  .navbar-inverse { background-image: none }
  .navbar-inverse { background: #fff; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color:#fff}
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form { border-color: #101010; border: 0;}
  .navbar-toggle .icon-bar {display: block;width: 22px;height: 3px;border-radius: 1px; }
  .navbar-inverse .navbar-toggle { margin-top:25px }
  .dropdown-menu { border-radius:0 }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #000; }
  .navbar-brand > img {max-width: 220px;margin-top: 10px; }
  .navbar-nav { font-family:sans-serif; }  
  #main-carousel .carousel-inner > .item { height: 500px; }
  #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%;WIDTH: 120PX;width: 120px;MARGIN: 0 auto; }
  #main-carousel .carousel-caption { bottom: 40%;  /*background: rgba(0, 0, 0, 0.5);*/  }
  #main-carousel .carousel-caption h2 { color: @color_white }
  #main-carousel .carousel-indicators {bottom: 70px;}
  .content_short_front { height: 123px;overflow: hidden; }
  #footer { background:#00C6D8;color: rgb(255, 255, 255);margin-top: 50px; }
  #footer p { margin:10px 0 }
  
  .news_content_short { height: 143px;overflow: hidden; }
  .product_content_short { height: 143px;overflow: hidden; }
  
  @media screen and (max-width: 768px) {
    #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%; }
    
  }
  
  @media screen and (max-width: 992px) {
    .news_list .btn-primary { margin-bottom:20px }
  }  
  
  #bxslider1 .zobacz_wiecej { position: absolute; left:0; top:0; width:100%; height:100%; z-index:1000;background: rgb(51, 122, 183) none repeat scroll 0% 0%;
color:@color_white;padding: 20px 30px;display: block;width: 170px; }
               
  .carousel-indicators .active {
    margin: 0px;
    width: 16px;
    height: 16px;
    background-color: @color_rozowy;
  }
  
  .carousel-indicators li {
     margin: 0px;
     width: 16px;
     height: 16px;
  }
  
  #main-carousel .carousel-inner h2 { font-size: 60px; }
  #main-carousel .carousel-inner p { font-size: 16px; }
  
  #main-carousel .carousel-left{
	left:0px;
  }
  
  #main-carousel .carousel-right{
  	right:-0px;
  }
  
  #main-carousel:hover .carousel-left{
  	left:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel:hover .carousel-right{
  	right:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel .carousel-left,
  #main-carousel .carousel-right{
  	padding: 15px;
  	z-index: 12;
  	font-size: 60px;
  	color: rgba(255, 255, 255, 0.52);
  	top: 50%;
  	position: absolute;
  	margin-top: -60px;
  	text-align: center;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  .carousel-right {
    right:65px
  }
  
  .carousel-left {
    left:65px
  }
  
  #main-carousel .carousel-left:hover,
  #main-carousel .carousel-right:hover{
  	color: #F6F6F6;
  }
  
  #main-carousel .btn-primary { color: @color_white;background-color: #F33B24;border-color: @color_rozowy;}

  .naglowek_strony { color: @color_rozowy;font-weight: bold;border-bottom: 1px solid rgb(243, 59, 36);margin-bottom: 20px; }
  hr { border-color:@color_czorny }
  
 
  h2 , h3 { color: @color_rozowy;font-weight: bold; }
  #news_short_mainpage , .news_list {
   .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {  background-color: #F33B24;
border-color: @color_rozowy }
  }
  
  
  #footer { -webkit-box-shadow: 1px 1px 5px 5px #808080;box-shadow: 1px 1px 5px 5px #808080; } 
  #footer .footer_menu { list-style:none;margin:0;padding:0;margin-top: 20px; }
  
  @media(max-width:992px){
    #footer .footer_menu { border-bottom: 1px solid #000; }
  }
  
  #footer .footer_menu li {  margin-bottom: 8px; }
  #footer .footer_menu li a { color:@color_white }
  #footer .footer_menu li a:hover { color:@color_czorny }
  
  #footer .dane_kontaktowe { margin-top: 20px; }
  #footer .dane_kontaktowe p { line-height: 24px;margin:0; }
  #footer .dane_kontaktowe a { color: @color_czorny; }
  
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 2;
    color: #FFF;
    background-color: #F33B24;
    border-color: #F33B24;
    cursor: default;
  }
  
  .pagination > li > a, .pagination > li > span { color: #F33B24; }
  
  .original_website { text-align: center;display: block;padding-bottom: 10px;color:@color_czorny;font-weight:bold }
  .original_website:hover { color:@color_czorny }
  
}


 /****************************** layoutmobi3 **************************************************/
 
 
#layoutmobi3{


  @font-face {
    font-family: 'signika-regular';
    src: url('/fonts/signika/signika-regular-webfont.eot');
    src: url('/fonts/signika/signika-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/signika/signika-regular-webfont.woff2') format('woff2'),
         url('/fonts/signika/signika-regular-webfont.woff') format('woff'),
         url('/fonts/signika/signika-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

  }

  @color_white: #fff;
  @color_niebieski: #1c70aa;
  @color_szary: #dfdfdf;
  @color_pomaranczowy: #ff8e05;
  @color_czarny: #333;
  

  .navbar{ min-height: 87px !important;}
  .navbar-inverse { border:0;border-bottom: 2px solid #FF8E05; }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover { background:none; }
  .navbar-inverse  .navbar-nav > li > a.active { }
  .navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a { text-shadow:none;text-transform:uppercase;color:#4b4b4b;font-size:16px }
  .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover { color: @color_pomaranczowy; }     
  .navbar-right { margin: 25px 0px 0px 10px; }
  .nav > li { list-style-type: disc;display: list-item;margin-right: 30px;color: #FF8E05; }
  .nav > li > a { padding-left:0px } 
  .navbar-inverse .navbar-toggle { border:0; }
  .navbar-inverse .navbar-toggle .icon-bar { background-color:@color_pomaranczowy; }
  .navbar-toggle { background:none }
  .navbar-nav .open .dropdown-menu { background:@color_white }
  .navbar-inverse { background-image: none }
  .navbar-inverse { background: @color_white; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color:@color_white}
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form { border-color: #101010; border: 0;}
  .navbar-toggle .icon-bar {display: block;width: 22px;height: 3px;border-radius: 1px; }
  .navbar-inverse .navbar-toggle { margin-top:25px }
  .dropdown-menu { border-radius:0 }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #000; }
  .navbar-brand > img { max-width: 160px;margin-top: 10px; }
  .navbar-nav { font-family: signika-regular; }  
  #main-carousel .carousel-inner > .item { height: 500px; }
  #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%;WIDTH: 120PX;width: 120px;MARGIN: 0 auto; }
  #main-carousel .carousel-caption { bottom: 40%;  background: rgba(0, 0, 0, 0.5);  }
  #main-carousel .carousel-caption h2 { color: @color_pomaranczowy; }
  #main-carousel .carousel-indicators {bottom: 70px;}
  .content_short_front { height: 123px;overflow: hidden; }
  #footer { background:#333;color: rgb(255, 255, 255);margin-top: 50px; }
  #footer p { margin:10px 0 }
  
  .news_content_short { height: 143px;overflow: hidden; }
  .product_content_short { height: 143px;overflow: hidden; }
  
  @media screen and(min-width:992px){
    .navbar-inverse {
      
    }
  }
  
  @media screen and (max-width: 768px) {
    #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%; }
    
  }
  
  @media screen and (max-width: 992px) {
    .news_list .btn-primary { margin-bottom:20px }
  }  
  
  #bxslider1 .zobacz_wiecej { position: absolute; left:0; top:0; width:100%; height:100%; z-index:1000;background: rgb(51, 122, 183) none repeat scroll 0% 0%;
color:@color_white;padding: 20px 30px;display: block;width: 170px; }
               
  .carousel-indicators .active {
    margin: 0px;
    width: 12px;
    height: 12px;
    background-color: @color_pomaranczowy;
  }
  
  .carousel-indicators li {
     margin: 0px;
     width: 12px;
     height: 12px;
     background-color: @color_white;
  }
  
  #main-carousel .carousel-inner h2 { font-size: 60px; }
  #main-carousel .carousel-inner p { font-size: 16px; }
  
  #main-carousel .carousel-left{
	left:0px;
  }
  
  #main-carousel .carousel-right{
  	right:-0px;
  }
  
  #main-carousel:hover .carousel-left{
  	left:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel:hover .carousel-right{
  	right:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel .carousel-left,
  #main-carousel .carousel-right{
  	padding: 15px;
  	z-index: 12;
  	font-size: 60px;
  	color: rgba(255, 255, 255, 0.52);
  	top: 50%;
  	position: absolute;
  	margin-top: -60px;
  	text-align: center;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  .carousel-right {
    right:65px
  }
  
  .carousel-left {
    left:65px
  }
  
  #main-carousel .carousel-left:hover,
  #main-carousel .carousel-right:hover{
  	color: #F6F6F6;
  }
  
  #main-carousel .btn-primary { color: @color_white;background-color: @color_pomaranczowy;border-color: @color_pomaranczowy;}

  .naglowek_strony { color: @color_czarny;font-weight: bold;border-bottom: 1px solid @color_czarny;margin-bottom: 20px;padding-bottom: 6px; }
  hr { border-color:@color_pomaranczowy }
  
 
  h2 , h3 { color: @color_pomaranczowy;font-weight: bold; }
  #news_short_mainpage , .news_list {
   .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {  background-color: @color_pomaranczowy;
border-color: @color_white }
  }
  
  .content_short_front { color:@color_szary }
  #news_short_mainpage{ 
    h2 { color:@color_pomaranczowy; }
    h3 { color:@color_pomaranczowy; }
    .btn-primary { background:@color_pomaranczowy !important;border-color:@color_pomaranczowy !important;color:@color_czarny }
   }
  
 
  #footer { padding: 20px 0;border-top: 2px solid #FF8E05;background:#fff }
  #footer p { margin:0;color:@color_czarny }
  #footer .footer_menu { list-style:none;margin:0;padding:0;margin-top: 0px; }
  #footer .footer_menu li {  margin-bottom: 8px; }
  #footer .footer_menu li a { color:@color_czarny }
  #footer .footer_menu li a:hover { color:@color_pomaranczowy }
  
  #footer .dane_kontaktowe { margin-top: 0px;color:@color_czarny }
  #footer .dane_kontaktowe p { line-height: 24px;margin:0; }
  #footer .dane_kontaktowe a { color: @color_pomaranczowy; }
  
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 2;
    color: #FFF;
    background-color: @color_pomaranczowy;
    border-color: @color_pomaranczowy;
    cursor: default;
  }
  
  .pagination > li > a, .pagination > li > span { color: @color_pomaranczowy; }
  
  .tlo_szare { background:@color_czarny;padding-bottom: 30px; }
  
}

body #layoutmobi3 { font-family: signika-regular; }


/****************************** layoutmobi4 **************************************************/
 
 
#layoutmobi4{

  

  @font-face {
    font-family: 'signika-regular';
    src: url('/fonts/signika/signika-regular-webfont.eot');
    src: url('/fonts/signika/signika-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/signika/signika-regular-webfont.woff2') format('woff2'),
         url('/fonts/signika/signika-regular-webfont.woff') format('woff'),
         url('/fonts/signika/signika-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

  }

  @color_white: #fff;
  @color_niebieski: #1c70aa;
  @color_szary: #dfdfdf;
  @color_pomaranczowy: #ff8e05;
  @color_czarny: #333;
 
  
  

  .navbar{ min-height: 90px !important;}
  .navbar-inverse { border:0; }
  .navbar-inverse { }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover { background:none; }
  .navbar-inverse  .navbar-nav > li > a.active { }
  .navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a { text-shadow:none;text-transform:uppercase;color:#4b4b4b;font-size:16px }
  .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover { color: @color_niebieski; }     
  
   .navbar-right { margin: 25px 0px 0px 10px; }
  .nav > li { list-style-type: disc;display: list-item;margin-right: 30px;color: #1C70AA; }
  .nav > li > a { padding-left:0px }
  .navbar-inverse .navbar-toggle { border:0; }
  .navbar-inverse .navbar-toggle .icon-bar { background-color:@color_niebieski; }
  .navbar-toggle { background:none }
  .navbar-nav .open .dropdown-menu { background:@color_white }
  .navbar-inverse { background-image: none }
  .navbar-inverse { background: @color_white; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color:@color_white}
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form { border-color: #101010; border: 0;}
  .navbar-toggle .icon-bar {display: block;width: 22px;height: 3px;border-radius: 1px; }
  .navbar-inverse .navbar-toggle { margin-top:25px }
  .dropdown-menu { border-radius:0 }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #000; }
  .navbar-brand > img { max-width: 160px;margin-top: 10px; }
  .navbar-nav { font-family: signika-regular; }  
  #main-carousel .carousel-inner > .item { height: 500px; }
  #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%;WIDTH: 120PX;width: 120px;MARGIN: 0 auto; }
  #main-carousel .carousel-caption { bottom: 40%;  background: rgba(0, 0, 0, 0.5);  }
  #main-carousel .carousel-caption h2 { color: @color_pomaranczowy; }
  #main-carousel .carousel-indicators {bottom: 70px;}
  .content_short_front { height: 123px;overflow: hidden; }
  #footer { background:#333;color: rgb(255, 255, 255);margin-top: 50px; }
  #footer p { margin:10px 0 }
  
  .news_content_short { height: 143px;overflow: hidden; }
  .product_content_short { height: 143px;overflow: hidden; }
  
  @media screen and(min-width:992px){
    .navbar-inverse {
      
    }
  }
  
  @media screen and (max-width: 768px) {
    #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%; }
    
  }
  
  @media screen and (max-width: 992px) {
    .news_list .btn-primary { margin-bottom:20px }
  }  
  
  #bxslider1 .zobacz_wiecej { position: absolute; left:0; top:0; width:100%; height:100%; z-index:1000;background: rgb(51, 122, 183) none repeat scroll 0% 0%;
color:@color_white;padding: 20px 30px;display: block;width: 170px; }
               
  .carousel-indicators .active {
    margin: 0px;
    width: 12px;
    height: 12px;
    background-color: @color_pomaranczowy;
  }
  
  .carousel-indicators li {
     margin: 0px;
     width: 12px;
     height: 12px;
     background-color: @color_white;
  }
  
  #main-carousel .carousel-inner h2 { font-size: 60px; }
  #main-carousel .carousel-inner p { font-size: 16px; }
  
  #main-carousel .carousel-left{
	left:0px;
  }
  
  #main-carousel .carousel-right{
  	right:-0px;
  }
  
  #main-carousel:hover .carousel-left{
  	left:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel:hover .carousel-right{
  	right:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel .carousel-left,
  #main-carousel .carousel-right{
  	padding: 15px;
  	z-index: 12;
  	font-size: 60px;
  	color: rgba(255, 255, 255, 0.52);
  	top: 50%;
  	position: absolute;
  	margin-top: -60px;
  	text-align: center;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  .carousel-right {
    right:65px
  }
  
  .carousel-left {
    left:65px
  }
  
  #main-carousel .carousel-left:hover,
  #main-carousel .carousel-right:hover{
  	color: #F6F6F6;
  }
  
  #main-carousel .btn-primary { color: @color_white;background-color: @color_pomaranczowy;border-color: @color_pomaranczowy;}

  .naglowek_strony { color: @color_czarny;font-weight: bold;border-bottom: 1px solid @color_czarny;margin-bottom: 20px;padding-bottom: 6px; }
  hr { border-color:@color_white }
  
 
  h2 , h3 { color: @color_niebieski;font-weight: bold; }
  #news_short_mainpage , .news_list {
   .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {  background-color: @color_pomaranczowy;
border-color: @color_white }
  }
  
  .news_list {
    
    h3 { color: @color_czarny }
    .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {  background-color: @color_czarny;
    border-color: @color_czarny }
  }
  
  .content_short_front { color:@color_white }
  #news_short_mainpage{
    img { border-radius:50%;-webkit-filter: grayscale(100%);
	   filter: grayscale(100%);
	   -webkit-transition: .3s ease-in-out;
	   transition: .3s ease-in-out; }   
  
    img:hover { -webkit-filter: grayscale(0);filter: grayscale(0); }   
          
    h2 { color:@color_white;border-bottom: 1px solid #fff;padding-bottom: 5px; }
    h3 { color:@color_white;border-bottom: 1px solid #fff;padding-bottom: 5px; }
    .btn-primary { color:@color_czarny;border-color: #fff !important;color: #333;
      background: #dddddd; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(#dddddd, #a4a4a4); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(#dddddd, #a4a4a4); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(#dddddd, #a4a4a4); /* For Firefox 3.6 to 15 */
      background: linear-gradient(#dddddd, #a4a4a4); /* Standard syntax */  
      
    }
   }
  
  #footer { padding: 20px 0;line-height: 30px;background:@color_niebieski }   
  
  #footer p { margin:0 }
  #footer a { color: @color_pomaranczowy }
  #footer .footer_menu { list-style:none;margin:0;padding:0;margin-top: 0px; }
  #footer .footer_menu li {  margin-bottom: 8px; }
  #footer .footer_menu li a { color:@color_white }
  #footer .footer_menu li a:hover { color:@color_pomaranczowy }
  
  #footer .dane_kontaktowe { margin-top: 0px; }
  #footer .dane_kontaktowe p { line-height: 24px;margin:0; }
  #footer .dane_kontaktowe a { color: @color_pomaranczowy; }  
  
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 2;
    color: @color_white;
    background-color: @color_czarny;
    border-color: @color_czarny;
    cursor: default;
  }
  
  .pagination > li > a, .pagination > li > span { color: @color_czarny; }
  
  .tlo_szare { background:@color_czarny;padding-bottom: 30px; }
  
  
}

body #layoutmobi4 { font-family: signika-regular;background:#EAEAEA }

tel { cursor:pointer }




/****************************** layoutmobi5 **************************************************/
 
 
#layoutmobi5{

  /**************************** menu lewe fixed start*******************************************************/
  @media screen and (min-width: 992px) {
    .menu_lewe { display:none; }
    .dane_kontaktowe { text-align: right;padding-top: 30px; }
    
  }
  @media screen and (max-width: 992px) {
    .navbar { display:none }
    .przycisk_nawigacji{ background: url(/layouts/layoutmobi5/images/btnSzablon4.png)no-repeat;
    height: 40px;
    width: 49px;
    position: fixed;
    top: 10px;
    left: 10px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    z-index: 9999;}
    .dane_kontaktowe { text-align: center;padding-top: 30px; }
       
    .obroc { transform: rotate(180deg); }
  
    .menu_lewe { margin:0;padding:0;padding: 20px 0;width: 200px;background:#FFA500;position:fixed;left:-200px;top:0;
      
      transition: all 0.5s ease-in-out;z-index:9999;
      transform:translate(0px,0px) rotateY(90deg);
       
       }
    .menu_lewe li { margin-bottom:15px;text-align:center;color:#CC8100;list-style-type: disc;
display: list-item;color: #E89302; }
    .menu_lewe li a { color:#fff;font-size:16px; }
    
    .przesun_prawo { transform: translate(200px,0px);  } 
    .przesun_prawo0 { transform: translate(200px,0px) rotateY(180deg); }
    .wroc_do_poczatku { transform: translate(0px,0px); } 
  }  
  /**************************** menu lewe end **************************************************************/

  @font-face {
    font-family: 'oswald-regular';
    src: url('/fonts/oswald/oswald-regular-webfont.eot');
    src: url('/fonts/oswald/oswald-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/oswald/oswald-regular-webfont.woff2') format('woff2'),
         url('/fonts/oswald/oswald-regular-webfont.woff') format('woff'),
         url('/fonts/oswald/oswald-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

  }
  
   @font-face {
    font-family: 'signika-regular';
    src: url('/fonts/signika/signika-regular-webfont.eot');
    src: url('/fonts/signika/signika-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/signika/signika-regular-webfont.woff2') format('woff2'),
         url('/fonts/signika/signika-regular-webfont.woff') format('woff'),
         url('/fonts/signika/signika-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

  }

  @color_white: #fff;
  @color_niebieski: #1c70aa;
  @color_jasnoniebieski:#A4BDCE;
  @color_szary: #dfdfdf;
  @color_pomaranczowy: #ff8e05;
  @color_czarny: #333;
  
  @czionka_signika:"signika-regular";
  
  @menu_color_czarny: #292929;
  
  @media screen and(min-width:992px){
    .wsmenu-list {  background:@menu_color_czarny;text-align:center }
    .wsmenu-list > li { display:inline-block }
    .wsmenu-list > li > a { color:@color_white;font-family: @czionka_signika; }
    
  }
  
  @media screen and(max-width:780px){
   .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
     background: @menu_color_czarny
   } 
   .animated-arrow.menuopen span { background:#7d7d7d }
    
  }  
  
  
  .navbar{ min-height: auto !important;}
  .navbar-inverse { border:0;border-radius:0 }
  .navbar-inverse { background:#000 !important }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover { background:none; }
  .navbar-inverse  .navbar-nav > li > a.active { }
  .navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a { text-shadow:none;text-transform:uppercase;
  color: @color_jasnoniebieski;font-size:16px;background: #000;border-radius: 0; }
  .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover { color: @color_niebieski; }     
  .navbar-right { margin: 7px 0px 7px; }
  .nav > li > a { padding:10px 27px } 
  .navbar-inverse .navbar-toggle { border:0; }
  .navbar-inverse .navbar-toggle .icon-bar { background-color:@color_niebieski; }
  .navbar-toggle { background:none }
  .navbar-nav .open .dropdown-menu { background:@color_white }
  .navbar-inverse { background-image: none }
  .navbar-inverse { background: @color_white; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color:@color_white}
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form { border-color: #101010; border: 0;}
  .navbar-toggle .icon-bar {display: block;width: 22px;height: 3px;border-radius: 1px; }
  .navbar-inverse .navbar-toggle { margin-top:5px }
  .dropdown-menu { border-radius:0 }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #000; }
  .navbar-brand > img { max-width: 160px;margin-top: 18px; }
  .navbar-nav { font-family: oswald-regular; }  
  #main-carousel .carousel-inner > .item { height: 500px; }
  #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%;WIDTH: 120PX;width: 120px;MARGIN: 0 auto; }
  #main-carousel .carousel-caption { bottom: 40%;  background: rgba(0, 0, 0, 0.5);  }
  #main-carousel .carousel-caption h2 { color: @color_pomaranczowy; }
  #main-carousel .carousel-indicators {bottom: 70px;}
  .content_short_front { height: 123px;overflow: hidden; }
  #footer { background:#333;color: rgb(255, 255, 255);margin-top: 50px; }
  #footer p { margin:10px 0 }
  
  .news_content_short { height: 143px;overflow: hidden; }
  .product_content_short { height: 143px;overflow: hidden; }
  
  @media screen and(min-width:992px){
    .navbar-inverse {
      
    }
  }
  
  @media screen and (max-width: 768px) {
    #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%; }
    
  }
  
  @media screen and (max-width: 992px) {
    .news_list .btn-primary { margin-bottom:20px }
    .czas { padding-top: 0px;padding-bottom:10px }
    
  }  
  
  #bxslider1 .zobacz_wiecej { position: absolute; left:0; top:0; width:100%; height:100%; z-index:1000;background: rgb(51, 122, 183) none repeat scroll 0% 0%;
color:@color_white;padding: 20px 30px;display: block;width: 170px; }
               
  .carousel-indicators .active {
    margin: 0px;
    width: 12px;
    height: 12px;
    background-color: @color_pomaranczowy;
  }
  
  .carousel-indicators li {
     margin: 0px;
     width: 12px;
     height: 12px;
     background-color: @color_white;
  }
  
  #main-carousel .carousel-inner h2 { font-size: 60px; }
  #main-carousel .carousel-inner p { font-size: 16px; }
  
  #main-carousel .carousel-left{
	left:0px;
  }
  
  #main-carousel .carousel-right{
  	right:-0px;
  }
  
  #main-carousel:hover .carousel-left{
  	left:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel:hover .carousel-right{
  	right:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel .carousel-left,
  #main-carousel .carousel-right{
  	padding: 15px;
  	z-index: 12;
  	font-size: 60px;
  	color: rgba(255, 255, 255, 0.52);
  	top: 50%;
  	position: absolute;
  	margin-top: -60px;
  	text-align: center;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  .carousel-right {
    right:65px
  }
  
  .carousel-left {
    left:65px
  }
  
  #main-carousel .carousel-left:hover,
  #main-carousel .carousel-right:hover{
  	color: #F6F6F6;
  }
  
  #main-carousel .btn-primary { color: @color_white;background-color: @color_pomaranczowy;border-color: @color_pomaranczowy;}

  .naglowek_strony { color: @color_czarny;font-weight: bold;border-bottom: 1px solid @color_czarny;margin-bottom: 20px;padding-bottom: 6px; }
  hr { border-color:@color_white }
  
 
  h2 , h3 { color: @color_niebieski;font-weight: bold; }
  #news_short_mainpage , .news_list {
   .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {  background-color: @color_pomaranczowy;
border-color: @color_white }
  }
  
  .news_list {
    
    h3 { color: @color_czarny }
    .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {  background-color: @color_czarny;
    border-color: @color_czarny }
  }
  
  .content_short_front { color:@color_white }
  #news_short_mainpage{
    img { border-radius:50%;-webkit-filter: grayscale(100%);
	   filter: grayscale(100%);
	   -webkit-transition: .3s ease-in-out;
	   transition: .3s ease-in-out; }   
  
    img:hover { -webkit-filter: grayscale(0);filter: grayscale(0); }   
          
    h2 { color:@color_white;border-bottom: 1px solid #fff;padding-bottom: 5px; }
    h3 { color:@color_white;border-bottom: 1px solid #fff;padding-bottom: 5px; }
    .btn-primary { color:@color_czarny;border-color: #fff !important;color: #333;
      background: #dddddd; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(#dddddd, #a4a4a4); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(#dddddd, #a4a4a4); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(#dddddd, #a4a4a4); /* For Firefox 3.6 to 15 */
      background: linear-gradient(#dddddd, #a4a4a4); /* Standard syntax */  
      
    }
   }
  
  #footer { padding: 20px 0;line-height: 30px;background:@color_niebieski }   
  
  #footer p { margin:0 }
  #footer a { color: @color_pomaranczowy }
  #footer .footer_menu { list-style:none;margin:0;padding:0;margin-top: 0px; }
  #footer .footer_menu li {  margin-bottom: 8px; }
  #footer .footer_menu li a { color:@color_white }
  #footer .footer_menu li a:hover { color:@color_pomaranczowy }
  
  #footer .dane_kontaktowe { margin-top: 0px; }
  #footer .dane_kontaktowe p { line-height: 24px;margin:0; }
  #footer .dane_kontaktowe a { color: @color_pomaranczowy; }  
  
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 2;
    color: @color_white;
    background-color: @color_czarny;
    border-color: @color_czarny;
    cursor: default;
  }
  
  .pagination > li > a, .pagination > li > span { color: @color_czarny; }
  
  .tlo_szare { background:@color_czarny;padding-bottom: 30px; }
  .logo { display:block;margin: auto;margin-top:30px;margin-bottom:30px }
  .logo img { display: block;text-align: center;margin: 0 auto;  }
  
  .contencik { background: #fff;margin-top:20px }
  
}

body #layoutmobi5 { font-family: 'signika-regular';background:#dfdfdf;min-height:1000px }


/****************************** layoutmobi6 **************************************************/
 
 
#layoutmobi6{

  /**************************** menu dol fixed start*******************************************************/
  @media screen and(min-width:992px){
     .menu_dol { display:none !important }
  }
  
  @media screen and(max-width:992px){
    .navbar { display:none }
    
    
    .przycisk_nawigacji{ background: url(/layouts/layoutmobi6/images/btnSzablon5.png)no-repeat;
    height: 40px;
    width: 49px;
    position: fixed;
    top: 10px;
    left: 10px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    z-index: 9999;}
    
    
    /*.przycisk_nawigacji span { display: block;width: 0;margin: 0px auto;border-right: 16px solid transparent;
    border-bottom: 30px solid #fff;border-left: 16px solid transparent;height: 0;padding-top: 0px;
    margin-top: 10px;}*/
    
    .menu_dol { margin:0;padding:0;padding: 10px 0;width:100%;background:#292929;position:fixed;left:0px;bottom:0;
      transition: all 0.5s ease-in-out;z-index:9999;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c4c4c+0,595959+12,666666+25,474747+39,2c2c2c+50,000000+51,111111+60,2b2b2b+76,1c1c1c+91,131313+100;Black+Gloss+%231 */
background: #4c4c4c; /* Old browsers */
background: -moz-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=0 ); /* IE6-9 */;
border-radius: 10px 10px 0px 0px; }
    .menu_dol li { margin-bottom:10px;text-align:center;border-bottom: 1px solid #989898;padding-bottom: 10px;color:#fff }
    .menu_dol li a { color:#fff;text-decoration:none;font-size: 16px; }
    .menu_dol li a:hover { color:#FFA500 }
    
    .menu_dol_gora { transform: rotate(180deg); }
  }  
  
  
  /**************************** menu dol end **************************************************************/

  @font-face {
    font-family: 'oswald-regular';
    src: url('/fonts/oswald/oswald-regular-webfont.eot');
    src: url('/fonts/oswald/oswald-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/oswald/oswald-regular-webfont.woff2') format('woff2'),
         url('/fonts/oswald/oswald-regular-webfont.woff') format('woff'),
         url('/fonts/oswald/oswald-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

  }

  @color_white: #fff;
  @color_niebieski: #1c70aa;
  @color_szary: #dfdfdf;
  @color_pomaranczowy: #ff8e05;
  @color_czarny: #333;
 
  
   
   @czionka_oswald:'oswald-regular';
  
    @media screen and(min-width:992px){
      .wsmenu-list {  text-align: center; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c4c4c+0,595959+12,666666+25,474747+39,2c2c2c+50,000000+51,111111+60,2b2b2b+76,1c1c1c+91,131313+100;Black+Gloss+%231 */
      background: #4c4c4c; /* Old browsers */
      background: -moz-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=0 ); /* IE6-9 */;
      border-radius: 10px 10px 0px 0px; }
      .wsmenu-list > li { display:inline-block }
      .wsmenu-list > li > a { color:@color_white;font-family: @czionka_oswald; }
      .wsmenu-list > li > a:hover { color:@color_pomaranczowy } 
    }
  
  @media screen and(max-width:780px){
   .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
     background: @color_czarny
   } 
   .animated-arrow.menuopen span { background:#8A6D1F }
    
  }  
   
   

  .navbar{ min-height: auto !important;text-align: center; }
  .navbar-inverse { border:0;border-radius:0;margin-bottom:0 !important;padding: 10px 0;margin-top:30px }
  .navbar-inverse { /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c4c4c+0,595959+12,666666+25,474747+39,2c2c2c+50,000000+51,111111+60,2b2b2b+76,1c1c1c+91,131313+100;Black+Gloss+%231 */
background: #4c4c4c; /* Old browsers */
background: -moz-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=0 ); /* IE6-9 */;
border-radius: 10px 10px 0px 0px; }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover { background:none; }
  
  @media screen and (min-width: 992px) {
    .navbar-inverse  .navbar-nav > li { float:initial;display:inline-block }
  }
  
  .navbar-inverse  .navbar-nav > li > a.active { }
  .navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a { text-shadow:none;text-transform:uppercase;
  color:@color_white;font-size:16px;border-radius: 0; }
  .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover { color: #FCCB47; }     
  .navbar-right { margin: 7px 0px 7px; }
  .nav > li > a { padding:10px 27px } 
  .navbar-inverse .navbar-toggle { border:0; }
  .navbar-inverse .navbar-toggle .icon-bar {  }
  .navbar-toggle { background:none }
  .navbar-nav .open .dropdown-menu { background:@color_white }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color:@color_white}
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form { border-color: #101010; border: 0;}
  .navbar-toggle .icon-bar {display: block;width: 22px;height: 3px;border-radius: 1px; }
  .navbar-inverse .navbar-toggle { margin-top:5px }
  .dropdown-menu { border-radius:0 }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #000; }
  .navbar-brand > img { max-width: 160px;margin-top: 18px; }
  .navbar-nav { font-family: oswald-regular;float:initial;text-align:center }  
  #main-carousel .carousel-inner > .item { height: 500px; }
  #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%;WIDTH: 120PX;width: 120px;MARGIN: 0 auto; }
  #main-carousel .carousel-caption { bottom: 40%;  background: rgba(0, 0, 0, 0.5);  }
  #main-carousel .carousel-caption h2 { color: @color_pomaranczowy; }
  #main-carousel .carousel-indicators {bottom: 70px;}
  .content_short_front { height: 123px;overflow: hidden; }
  #footer { background:#333;color: rgb(255, 255, 255); }
  #footer p { margin:10px 0 }
  
  .news_content_short { height: 143px;overflow: hidden; }
  .product_content_short { height: 143px;overflow: hidden; }
  
  @media screen and(min-width:992px){
    .navbar-inverse {
      
    }
  }
  
  @media screen and (max-width: 768px) {
    #main-carousel .main-carousel_more { position: absolute;bottom: 20px;left: 10%;right: 10%; }
    
  }
  
  @media screen and (max-width: 992px) {
    .news_list .btn-primary { margin-bottom:20px }
    .czas { padding-top: 0px;padding-bottom:10px }
  }  
  
  #bxslider1 .zobacz_wiecej { position: absolute; left:0; top:0; width:100%; height:100%; z-index:1000;background: rgb(51, 122, 183) none repeat scroll 0% 0%;
color:@color_white;padding: 20px 30px;display: block;width: 170px; }
               
  .carousel-indicators .active {
    margin: 0px;
    width: 12px;
    height: 12px;
    background-color: @color_pomaranczowy;
  }
  
  .carousel-indicators li {
     margin: 0px;
     width: 12px;
     height: 12px;
     background-color: @color_white;
  }
  
  #main-carousel .carousel-inner h2 { font-size: 60px; }
  #main-carousel .carousel-inner p { font-size: 16px; }
  
  #main-carousel .carousel-left{
	left:0px;
  }
  
  #main-carousel .carousel-right{
  	right:-0px;
  }
  
  #main-carousel:hover .carousel-left{
  	left:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel:hover .carousel-right{
  	right:0px;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  #main-carousel .carousel-left,
  #main-carousel .carousel-right{
  	padding: 15px;
  	z-index: 12;
  	font-size: 60px;
  	color: rgba(255, 255, 255, 0.52);
  	top: 50%;
  	position: absolute;
  	margin-top: -60px;
  	text-align: center;
  	-webkit-transition: 300ms;
  	-moz-transition: 300ms;
  	-o-transition: 300ms;
  	transition: 300ms;
  }
  
  .carousel-right {
    right:65px
  }
  
  .carousel-left {
    left:65px
  }
  
  #main-carousel .carousel-left:hover,
  #main-carousel .carousel-right:hover{
  	color: #F6F6F6;
  }
  
  #main-carousel .btn-primary { color: @color_white;background-color: @color_pomaranczowy;border-color: @color_pomaranczowy;}

  .naglowek_strony { color: @color_czarny;font-weight: bold;border-bottom: 1px solid @color_czarny;margin-bottom: 20px;padding-bottom: 6px; }
  hr { border-color:@color_white }
  
 
  h2 , h3 { color: @color_czarny;font-weight: bold; }
  #news_short_mainpage , .news_list {
   .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {  background-color: @color_pomaranczowy;
border-color: @color_white }
  }
  
  .news_list {
    
    h3 { color: @color_czarny }
    .btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {  background-color: @color_czarny;
    border-color: @color_czarny }
  }
  
  .content_short_front { color:@color_white }
  #news_short_mainpage{
    img { border-radius:50%;-webkit-filter: grayscale(100%);
	   filter: grayscale(100%);
	   -webkit-transition: .3s ease-in-out;
	   transition: .3s ease-in-out; }   
  
    img:hover { -webkit-filter: grayscale(0);filter: grayscale(0); }   
          
    h2 { color:@color_white;border-bottom: 1px solid #fff;padding-bottom: 5px; }
    h3 { color:@color_white;border-bottom: 1px solid #fff;padding-bottom: 5px; }
    .btn-primary { color:@color_czarny;border-color: #fff !important;color: #333;
      background: #dddddd; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(#dddddd, #a4a4a4); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(#dddddd, #a4a4a4); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(#dddddd, #a4a4a4); /* For Firefox 3.6 to 15 */
      background: linear-gradient(#dddddd, #a4a4a4); /* Standard syntax */  
      
    }
   }
  
  #footer { line-height: 30px;
background: #5955959;
border-radius: 10px;padding-top:20px;padding-bottom:20px;border-radius: 0 0 10px 10px; }   
  
  #footer p { margin:0 }
  #footer a { color: @color_pomaranczowy }
  #footer .footer_menu { list-style:none;margin:0;padding:0;margin-top: 0px; }
  #footer .footer_menu li {  margin-bottom: 8px; }
  #footer .footer_menu li a { color:@color_white }
  #footer .footer_menu li a:hover { color:@color_pomaranczowy }
  
  #footer .dane_kontaktowe { margin-top: 0px; }
  #footer .dane_kontaktowe p { line-height: 24px;margin:0; }
  #footer .dane_kontaktowe a { color: @color_pomaranczowy; }  
  
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 2;
    color: @color_white;
    background-color: @color_czarny;
    border-color: @color_czarny;
    cursor: default;
  }
  
  .pagination > li > a, .pagination > li > span { color: @color_czarny; }
  
  .tlo_szare { background:@color_czarny;padding-bottom: 30px; }
  .logo1 { margin: 30px 0px;display:block }
  
  .contencik { background: #fff;margin-top:0px;border-radius: 0px 0px 0px 0px;padding-bottom:30px;padding-top:10px }
  
}

body #layoutmobi6 { /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fefcea+0,f1da36+100;Gold+3D */
background: rgb(254,252,234); /* Old browsers */
background: -moz-linear-gradient(top, rgb(252,247,201) 0%, rgb(241,218,54) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgb(252,247,201) 0%,rgb(241,218,54) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgb(252,247,201) 0%,rgb(241,218,54) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefcea', endColorstr='#f1da36',GradientType=0 ); /* IE6-9 */ }    







  
